.products-page {
  display: flex;
  justify-content: center;
}

.products-content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.products-intro {
  margin: 3rem 1rem 2rem;
}

.products-intro p {
  margin: 1rem 1rem 1rem
}