.services-page {
  display: flex;
  justify-content: center;
}

.services-content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.services-intro {
  margin: 3rem 1rem 0rem;
}
.services-intro p {
  margin: 1rem;
}

.services-section-content {
  margin: 2rem;
}

.services-section-content p {
  margin: 1rem;
}