.contact-page {
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: calc( 100vh 
        - var(--bannerHeight) 
        - var(--navbarHeight) 
        - var(--footerHeight)
    );
    background-position: center;
    background-size: cover;
}

.contact-container {
    display: flex;
    width: 100%;
    max-width: 1500px;

    background-color: white;
    margin-top: 20rem;
    padding: 2rem 3rem 1rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;

    box-shadow: 
    inset 0 -10px 10px -10px #000000,
    0 0px 10px rgb(0 0 0 / 0.4);;
}

@media screen and (max-width: 1000px) {
    .contact-container {
        flex-direction: column;
  }
}

.contact-us-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
}

.contact-subheading {
    margin: 0.5rem 0 1rem;
}

.contact-us-content {
    display: flex;
    flex-direction: column;
}

.contact-us-detail-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1rem 0 1rem;
}

.contact-us-detail-heading {
    display: flex;
}

.contact-us-detail-details {
    display: grid;
    grid-template-columns: 1fr 2fr;
}

/* Contact Form */

.contact-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
}

.contact-form-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 1rem 0 1rem;
}

.contact-form-content > * {
    display: flex;
    flex-direction: column;
}

.contact-form-input-message {
    min-height: 100px;
}

.contact-form-input-submit {
    width: 50%;
    padding: 0.5rem 1rem 0.5rem;
}