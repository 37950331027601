* {
  margin: 0;
  padding: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  /* font-family: "Comic Sans MS", "Comic Sans", cursive; */
}

:root {
  --navy1: #232335;
  --navy2: #1a1a19;
  --orange1: #fb9b50;
  --orange2: #F98125;
  --text1: #0b87aa;
}