.cardslider-relative {
  position: relative;
  display: flex;
  width: 100%;
}

.cardslider-content {
  display: flex; 
  width: 100%;
  margin: auto;
  justify-content: center;
  padding: 1rem 0 1rem;
  margin: 1rem 0 2rem;
  /* background-color: beige; */
}

.cardslider-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;


  border-radius: 1rem;
  height: 10rem;
  margin: 1rem 1rem;
  cursor: grab;

  background-position: center;
  background-size: cover;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  text-shadow: 
  0 -1px 10px rgba(0,0,0,.5),
  10px 0 10px rgba(0,0,0,.5),
  -10px 0 10px rgba(0,0,0,.5),
  0 10px 10px rgba(0,0,0,.5);
}

.cardslider-card:active {
  cursor: grabbing;
}

.csc-fade-left {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 85%;
  width: 4rem;

  background-image: linear-gradient(to left, 
  rgba(255, 255, 255, 0), 
  rgb(255, 255, 255));
}

.csc-fade-right {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 85%;
  width: 4rem;

  background-image: linear-gradient(to right, 
  rgba(255, 255, 255, 0), 
  rgb(255, 255, 255));
}