.home-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background-color: blanchedalmond; */
}

/* ############# */
/* Hero Carousel */
/* ############# */

.hero-slider-container {
  width: 100%;
  height: calc(100vh - var(--bannerHeight) - var(--navbarHeight));
  /* margin: 0 auto; */
  margin-bottom: 0;
}

/* ############# */
/* Hero Cards */
/* ############# */

.product-cards-title-container {
  display: flex;
  justify-content: center;
}

.product-cards-title-content {
  margin-bottom: 1rem;
}

.product-cards-container {
  display: flex;
  background-color: rgb(243, 243, 243);
  justify-content: center;
  box-shadow: 
    inset 0 20px 20px -20px #000000,
    inset 0 -20px 20px -20px #000000;
}

.product-cards-content {
  display: grid;
  margin: 0 1rem 0;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  /* background-color: bisque; */
  padding: 2rem 0 2rem;
}

@media screen and (max-width: 1500px) {
  .product-cards-content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
}

@media screen and (max-width: 800px) {
  .product-cards-content {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
}

.product-card-map {
  position: relative;
  background-position: center;
  background-size: cover;
  background-color: black;
  border-radius: 0.5rem;
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
  transition: ease-in-out 200ms;
  cursor: pointer;

  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

  text-shadow: 
  0 -1px 10px rgba(0,0,0,.5),
  10px 0 10px rgba(0,0,0,.5),
  -10px 0 10px rgba(0,0,0,.5),
  0 10px 10px rgba(0,0,0,.5);
}

.product-card-map:hover {
  transform: scale(1.05);
}

/* .pcm-topheading {
  display: flex;
  position: absolute;
  top: 2rem;
  transform: translate(-50%, 0);
  left: 50%;
} */

.pcm-subheading {
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: 1rem;
  padding: 0.1rem 0.5rem 0.2rem;
  transform: translate(-50%, 0);
  left: 50%;
  background-color: var(--navy2);
  color: white;
  border-radius: 0.3rem;
  width: 6rem;
  text-shadow: none;
  transition: ease-in-out 100ms;
}

.pcm-subheading:hover {
  background-color: var(--text1);
  color: var(--navy2);
}

/* ########### */
/* About Adsys */
/* ########### */

.summary-container {
  display: flex;
  justify-content: center;
}

.summary-content {
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  max-width: 1500px;
  margin: 0 1rem 0;
}

.summary-image {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

@media screen and (max-width: 1200px) {
  .summary-content{
    grid-template-columns: 1fr;
  }
  .summary-image {
  display: none;
  }    
}

.summary-text {
  padding: 0 20px 20px;
  max-width: 1000px;
}

.summary-text h1 {
  text-align: left;
}

.summary-text p {
  text-align: justify;
}

/* ################ */
/* Conveyer Systems */
/* ################ */

.conveyersystems-container {
  display: flex;
  justify-content: center;
}

.conveyersystems-content {
  display: grid;
  grid-template-columns: 5fr 2fr;
  column-gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  max-width: 1500px;
  margin: 0 1rem 0;
}

.conveyersystems-image {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

@media screen and (max-width: 1200px) {
  .conveyersystems-content{
    grid-template-columns: 1fr;
  }
  .conveyersystems-image {
  display: none;
  }    
}


.conveyersystems-text {
  padding: 0 20px 20px;
  max-width: 1000px;
  /* min-width: 750px; */
}

.conveyersystems-text h1 {
  text-align: left;
}

.conveyersystems-text p {
  text-align: justify;
}

/* cards */
.convsystemsslider-container {
  position: relative;
  display: flex;
  justify-content: center;
  /* background-color: black; */
}


/* ############### */
/* Control Systems */
/* ############### */

.controlservices-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem 0;
}

.controlservices-content {
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  max-width: 1500px;
  margin: 0 1rem 0;
}

.controlservices-image {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}


@media screen and (max-width: 1200px) {
  .controlservices-content{
    grid-template-columns: 1fr;
  }
  .controlservices-image {
    display: none;
  }    
}

.controlservices-text {
  padding: 0 20px 20px;
  max-width: 1000px;
  /* min-width: 750px; */
}

.controlservices-text h1 {
  text-align: left;
}

.controlservices-text p {
  text-align: justify;
}

.controlservices-logos {
  display:flex;
  margin-top: 2rem;
}

@media screen and (max-width: 1000px) {
  .controlservices-logos {
    flex-direction: column;
  }
}

.controlservices-logos > * {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 0 2rem;
}

.controlservices-logos > * > * {
  margin-left: 2rem;
  list-style-type: none;
}


.controlservices-logos-disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem 1rem;
}

/* ################ */
/* Gantry Crane Section */
/* ################ */

.gantrysection-container {
  display: flex;
  justify-content: center;
}

.gantrysection-content {
  display: grid;
  grid-template-columns: 5fr 2fr;
  column-gap: 2rem;
  row-gap: 2rem;
  width: 100%;
  max-width: 1500px;
  margin: 0 1rem 0;
}

.gantrysection-image {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

@media screen and (max-width: 1200px) {
  .gantrysection-content{
    grid-template-columns: 1fr;
  }
  .gantrysection-image {
  display: none;
  }    
}

.gantrysection-text {
  padding: 0 20px 20px;
  max-width: 1000px;
  /* min-width: 750px; */
}

.gantrysection-text h1 {
  text-align: left;
}

.gantrysection-text p {
  text-align: justify;
}