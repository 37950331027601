.sca-grid {
  display: grid;
  grid-template-columns: minmax(200px, 400px) auto;
  grid-template-rows: minmax(200px, auto);
  margin: 1rem 2rem 1rem;
}

@media screen and (max-width: 700px) {
  .sca-grid {
    grid-template-columns: minmax(200px, auto);;
    grid-template-rows: minmax(200px, 300px) auto;
  }
}


.sca-image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.sca-image {
  display: flex;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

.sca-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 3rem 0;
}

.sca-content ul {
  list-style-type: disc;
  margin-left: 3rem;
  margin-top: 0.5rem;
}