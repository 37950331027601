:root {
  --bannerHeight: 100px;
  --navbarHeight: 40px;
  --headerHeight: calc(var(--bannerHeight) + var(--navbarHeight))
}

/* ########## */
/* Header CSS */
/* ########## */

.banner-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--bannerHeight);
  background-color: var(--navy1);
  z-index: 998;
}

.banner-content {
  display: flex;
  width: 100%;
  max-width: 1500px;
  padding: 0 2rem 0;
}

.banner-logo {
  margin: 0.7rem 1rem 0.7rem 0rem;
}

/* ########## */
/* Navbar CSS */
/* ########## */

.navbar-container {
  position: sticky;
  z-index: 10;
  top: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: var(--navbarHeight);
  background-color: var(--navy2);
  z-index: 999;
  
  box-shadow: 0 5px 8px rgb(0 0 0 / 0.5);
}

.navbar-btns-container {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1500px;
  padding-top: 0.5rem;
}


.navbar-default {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.navbar-default > * {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 8rem;
  align-items: center;
  cursor: pointer;
  color: var(--text1);
  list-style-type: none;
  text-decoration: none;
  font-weight: bold;

  background-color: var(--navy2);
  transition: ease-in-out 200ms;
}

.navbar-dropdown {
  position: absolute;
  top: var(--navbarHeight);
  display: none;
  flex-direction: column;
  height: 100%;
  transition: all 1s;
}

.navbar-dropdown > * {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 10rem;
  align-items: center;
  cursor: pointer;
  color: var(--text1);
  list-style-type: none;
  text-decoration: none;
  font-weight: bold;

  background-color: var(--navy2);
  transition: ease-in-out 200ms;
}

.navbar-dropdown-active {
  position: absolute;
  top: var(--navbarHeight);
  display: none;
  flex-direction: column;
  height: 100%;
}

.navbar-dropdown-active > * {
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 10rem;
  align-items: center;
  cursor: pointer;
  color: var(--text1);
  list-style-type: none;
  text-decoration: none;
  font-weight: bold;

  background-color: var(--navy2);
  transition: ease-in-out 200ms;
}

.navbar-btns-container > * bar {
  display: flex;
  margin-top: 0.4rem;
  height: 0.2rem;
  width: 100%;
  background-color: var(--orange2);
  transform: scaleX(0);
  transform-origin: left;
  transition: ease-in-out 200ms;
}

.home-navbar:hover .home-nav-highlight {
  transform: scaleX(1);
}

.products-navbar:hover .products-nav-highlight {
  transform: scaleX(1);
}

.control-navbar:hover .control-nav-highlight {
  transform: scaleX(1);
}

.services-navbar:hover .services-nav-highlight {
  transform: scaleX(1);
}

.about-navbar:hover .about-nav-highlight {
  transform: scaleX(1);
}

.gallery-navbar:hover .gallery-nav-highlight {
  transform: scaleX(1);
}

.contact-navbar:hover .contact-nav-highlight {
  transform: scaleX(1);
}

.adsysapp-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 6rem;
  text-decoration: none;
  cursor: pointer;
  padding: 0 1rem 0 1rem;
  background-color: rgb(56, 79, 129);
  color: black;
}

.hamburger, .hamburger-active{
  position: absolute;
  z-index: 100;
  display: none;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 4rem;
  cursor: pointer;
  margin-bottom: 0.7rem;
}

.hamburger > * , .hamburger-active > * {
  display: flex;
  background-color: var(--text1);
  margin-top: 5px;
  height: 5px;
  width: 65%;
  border-radius: 5px;
  transition: ease-in-out 200ms;
}

.hamburger-1 {
  position: absolute;
  top:5px;
  visibility: visible;
}

.hamburger-2 {
  position: absolute;
}

.hamburger-3 {
  position: absolute;
}

.hamburger-4 {
  position: absolute;
  bottom: 5px;
  visibility: visible;
}

.hamburger:hover > * {
  background-color: whitesmoke;
}

.hamburger:hover ~ .navbar-dropdown {
  display: flex;
}

.navbar-dropdown:hover {
  display: flex;
}

.hamburger-1-active {
  opacity: 0;
}

.hamburger-2-active {
  position: absolute;
  transform: rotate(45deg);
}

.hamburger-3-active {
  position: absolute;
  transform: rotate(-45deg);
}

.hamburger-4-active {
  opacity: 0;
}

.hamburger-active:hover > .hamburger-2-active {
  background-color: red;
}

.hamburger-active:hover > .hamburger-3-active {
  background-color: red;
}

@media screen and (max-width: 800px) {
  .hamburger, .hamburger-active {
    display: flex;
  }
  .navbar-default {
    display: none;
  }
  .navbar-dropdown-active {
    display: flex;
  }
}