.gallery-page {
  display: flex;
  justify-content: center;
}

.gallery-content {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  width: 100%;
  margin: 3rem 2rem 2rem;
}

.gallery-image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1500px) {
  .gallery-image-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .gallery-image-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 750px) {
  .gallery-image-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gallery-thumbnails {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 200px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.5);
  border-radius: 0.5rem;
  margin: 1rem;
  transition: ease 200ms;
}

.gallery-thumbnails:hover {
  cursor: pointer;
  transform: scale(110%);
  transition: ease 200ms;
}

.gallery-modal-open {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gallery-modal-closed {
  display: none;
}

.gallery-modal-image {
  background-color: black;
  background-position: center;
  background-size: cover;
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 0.5rem;
  margin: 1rem;
  cursor: default ;
  pointer-events: none;
  user-select: none;
  z-index: 1000;
}