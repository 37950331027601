.systems-page {
  background-color: rgb(14, 131, 131);
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--headerHeight) - var(--footerHeight));
}

.systems-navigation-container {
  background-color: beige;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* max-height: 50%; */
  align-items: center;
}

.conveyers-navigation-container {
  background-color: blanchedalmond;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

/* systems-navigation-grid */

.systems-navigation-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1500px;
  align-items: center;
}

.systems-navigation-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
  width: 100%;
  padding: 0 1rem 0;
}

.systems-navigation-grid-card {
  background-color: aqua;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 100%; 
  margin: 0 1rem 0;
}

.systems-navigation-grid-card li {
  list-style-type: none;
}