.sliderStyles  {
  height: 100%;
  position: relative;
  display: flex;
}

.slideStyles {
  opacity: 1;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition: ease-in-out 700ms;
}

.slideStylesOff {
  opacity: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  transition-delay: 700ms;
  transition-property: opacity;
}

/* IMAGE TEXT AND ICONS */

.slideTextContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 47%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 80%;
  color: #fff;
  z-index: 2;
  align-items: center;
  justify-content: center;

  opacity: 1;
  text-shadow: 
  0 -1px 10px rgba(0,0,0,.5),
  10px 0 10px rgba(0,0,0,.5),
  -10px 0 10px rgba(0,0,0,.5),
  0 10px 10px rgba(0,0,0,.5);

  transition: ease-in 800ms;
}

.slideTextContainerOff {
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 47%;
  transform: translate(-50%, -30%);
  left: 50%;
  width: 80%;
  color: #fff;
  z-index: 2;
  align-items: center;
  justify-content: center;

  opacity: 0;
  text-shadow: 
  0 -1px 10px rgba(0,0,0,.5),
  10px 0 10px rgba(0,0,0,.5),
  -10px 0 10px rgba(0,0,0,.5),
  0 10px 10px rgba(0,0,0,.5);
}

.hero-title {
  font-size: 40px;
  margin: 0 4rem 0;
}

.hero-subtitle {
  margin-top: 1rem;
  font-size: 20px;
}

.leftArrowStyles  {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  font-size: 45px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  height: 30%;
  width: 10%;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem 0;
  transition: ease-in-out 100ms;
}

.rightArrowStyles  {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  font-size: 45px;
  color: #fff;
  z-index: 2;
  cursor: pointer;
  height: 30%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1rem 0;
  transition: ease-in-out 100ms;
}

.dotsContainerStyles  {
  position: absolute;
  z-index: 2;
  font-size: 45px;
  bottom: 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content:center;
  align-items: flex-end;
}

.dotStyles  {
  margin: 0 3px;
  cursor: pointer;
  color: #fff
}

.dotStylesActive  {
  margin: 0 3px;
  cursor: pointer;
  color: #808080
}

/* On Hover CSS */

.leftArrowStyles:hover {
  font-size: 90px;
  transition: ease-in-out 200ms;
}

.rightArrowStyles:hover {
  font-size: 90px;
  transition: ease-in-out 200ms;
}