:root {
  --footerHeight: 200px;
}

/* Footer CSS Responsivness */

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: var(--footerHeight);

  box-shadow: 0 -5px 8px rgb(0 0 0 / 0.5);
}

.footer-upper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: var(--navy1);
}

.footer-upper-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  color: aliceblue;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.footer-lower {
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: var(--navy2);
  height: 40px;
}

.footer-lower-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  color: var(--text1);
  margin-left: 1rem;
  margin-top: 0.2rem;
}