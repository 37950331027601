.section-break-container {
  display: flex;
  justify-content: center;
  /* background-color: aqua; */
  padding: 0 5rem 0;
}

.section-break-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  height: 5vh;
}

.section-break-element {
  display: flex;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}