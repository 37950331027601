.about-page {
  display: flex;
  justify-content: center;
}

.about-content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
}

.about-intro {
  margin: 3rem 1rem 0rem;
}

.about-intro p {
  margin: 1rem 0rem 0rem;
}

.about-section-content {
  margin: 1rem 1rem 1rem;
}

.timeline-container {
  margin: 1rem 1rem 1rem;
}

.timeline-row {
  display: grid;
  grid-template-columns: 100px auto;
}

.timeline-year {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeline-year-line {
  background-color: darkblue;
  width: 2px;
  height: 100%;
  margin: 0.5rem 0 0.5rem;
}

.timeline-row-content {
  display: grid;
  grid-template-columns: 230px auto;
  margin: 0 0 1rem;
}

.timeline-row-content i {
  justify-self: end;
  margin-right: 2rem;
}